import React, { Component,Fragment } from 'react'
import { Container,Row,Col } from 'react-bootstrap';
import HomeSlider from './HomeSlider';
import MegaMenu from './MegaMenu';
 


export class HomeTop extends Component {
  render() {
    return (
      <Fragment  >
          <Container fluid={true}  style={{overflowX: 'hidden',overflowY: 'hidden'}}>
              <Row>
                  <Col lg={3} md={3} sm={12} >
                       <MegaMenu />  
                  </Col>
                  <Col lg={9} md={9} sm={12}  >
                  <HomeSlider />
                  </Col>
              </Row>
          </Container>
        
      </Fragment>
    )
  }
}

export default HomeTop
