 
import React, { Component } from 'react'

export class MegaMenu extends Component {
    constructor(props){
            super();
            this.MegaMenu.bind(this);
    }

    componentDidMount(){
        this.MegaMenu();
    }


    MegaMenu(){
        
        var acc=document.getElementsByClassName("accordion");
        var accNum=acc.length;
        //Process all Menu
        for(var i=0;i<accNum;i++){
    
                acc[i].addEventListener("click",function(){
                    this.classList.toggle("active");
                   

                   var panel= this.nextElementSibling;
                   
                   if(panel.style.maxHeight){
                     
                       panel.style.maxHeight = null;
                   }else {
                       panel.style.maxHeight = panel.scrollHeight+"px";
                   
                   }                  

        })

    }
}


  render() {
    return (
      <div  className="accordionMenuDiv">
               <div className="accordionMenuDivInside">
                
                
                 <button className="accordion">
                     <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQX48JYpPPow8zQXp34oKHyqRbECSs1dUpOdw&usqp=CAU"
                      className='accordionMenuIcon' 
                      alt="im"/>
                       &nbsp; Man clothing
                 </button>
                 <div className='panel'>
                        <ul>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                        </ul>
                 </div>
             
                 <button className="accordion">
                     <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQX48JYpPPow8zQXp34oKHyqRbECSs1dUpOdw&usqp=CAU"
                      className='accordionMenuIcon' 
                      alt="im"/>
                       &nbsp; Man clothing
                 </button>
                 <div className='panel'>
                        <ul>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                        </ul>
                 </div>

                 <button className="accordion">
                     <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQX48JYpPPow8zQXp34oKHyqRbECSs1dUpOdw&usqp=CAU"
                      className='accordionMenuIcon' 
                      alt="im"/>
                       &nbsp; Man clothing
                 </button>
                 <div className='panel'>
                        <ul>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                        </ul>
                 </div>


                 <button className="accordion">
                     <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQX48JYpPPow8zQXp34oKHyqRbECSs1dUpOdw&usqp=CAU"
                      className='accordionMenuIcon' 
                      alt="im"/>
                       &nbsp; Man clothing
                 </button>
                 <div className='panel'>
                        <ul>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                        </ul>
                 </div>

                 <button className="accordion">
                     <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQX48JYpPPow8zQXp34oKHyqRbECSs1dUpOdw&usqp=CAU"
                      className='accordionMenuIcon' 
                      alt="im"/>
                       &nbsp; Man clothing
                 </button>
                 <div className='panel'>
                        <ul>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                        </ul>
                 </div>

                 <button className="accordion">
                     <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQX48JYpPPow8zQXp34oKHyqRbECSs1dUpOdw&usqp=CAU"
                      className='accordionMenuIcon' 
                      alt="im"/>
                       &nbsp; Man clothing
                 </button>
                 <div className='panel'>
                        <ul>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                        </ul>
                 </div>

                 <button className="accordion">
                     <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQX48JYpPPow8zQXp34oKHyqRbECSs1dUpOdw&usqp=CAU"
                      className='accordionMenuIcon' 
                      alt="im"/>
                       &nbsp; Man clothing
                 </button>
                 <div className='panel'>
                        <ul>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                        </ul>
                 </div>

                 <button className="accordion">
                     <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQX48JYpPPow8zQXp34oKHyqRbECSs1dUpOdw&usqp=CAU"
                      className='accordionMenuIcon' 
                      alt="im"/>
                       &nbsp; Man clothing
                 </button>
                 <div className='panel'>
                        <ul>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                        </ul>
                 </div>

                 <button className="accordion">
                     <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQX48JYpPPow8zQXp34oKHyqRbECSs1dUpOdw&usqp=CAU"
                      className='accordionMenuIcon' 
                      alt="im"/>
                       &nbsp; Man clothing
                 </button>
                 <div className='panel'>
                        <ul>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                        </ul>
                 </div>
                 <button className="accordion">
                     <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQX48JYpPPow8zQXp34oKHyqRbECSs1dUpOdw&usqp=CAU"
                      className='accordionMenuIcon' 
                      alt="im"/>
                       &nbsp; Man clothing
                 </button>
                 <div className='panel'>
                        <ul>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                            <li> <a href='#' className='accordionItem'> Man Tshirts</a> </li>
                        </ul>
                 </div>
              </div>
      </div>
    )
  }
}

export default MegaMenu
