import React, { Component, Fragment } from 'react'
import { Navbar, Container,Row,Col,Button} from 'react-bootstrap'
import logo from '../../assets/images/easyshop.png';
import { Link } from "react-router-dom";
import MegaMenuAll from '../home/MegaMenuAll';

export class NavMenuDesktop extends Component {
  
constructor(props){
  super();
  this.state={
    SideNavState:"sideNavClose",
    ContentOverState: "ContentOverlayClose"
  }
  

}

MenuBarClickHandler= () => {
  this.SideNavOpenClose();

}

ContentOverkayClickHandler=()=>{
      this.SideNavOpenClose();
}

SideNavOpenClose=()=> {
   let SideNavState=this.state.SideNavState;
   let ContentOverState=this.state.ContentOverState;
      
      if(SideNavState==="sideNavOpen"){
        this.setState({SideNavState:"sideNavClose",ContentOverState:"ContentOverlayClose"});
       }
       else{
         this.setState({SideNavState:"sideNavOpen",ContentOverState:"ContentOverlayOpen"});
       }


}
  render() {
    return (
      <Fragment>
        <div className='TopSectionDown' >
              <Navbar fixed={"top"} className="navbar" bg="light" >
                
                 <Container fluid={true}  className="fixed-top shadow-sm bg-white p-2 mb-0 ">
                 <Row className="w-100">
                   <Col lg={4} md={4}>
                   <Button className="btn" onClick={this.MenuBarClickHandler}>
                    <i className='fa fa-bars'></i>
                   </Button>
                        <Navbar.Brand href="#">
                        <img src={logo} />
                      </Navbar.Brand>  
                   </Col>
                   <Col lg={4} md={4}>
                   <div className="input-group w-100"> 
                                <input type="text" className="form-control" />
                                <Button type="button" className="btn site-btn">
                                <i className="fa fa-search"></i>
                                </Button>
                   </div> 
                   </Col>
                   <Col lg={4} md={4}>
                   <div className="w-100  ">

                   <Link to="/" className="btn">
                     <i className='fa h4 fa-heart text-danger' ></i> 
                     <sup><span className='badge text-white bg-danger'>5</span></sup>
                   </Link>
                   <Link to="/" className="btn ">
                     <i className='fa h4 fa-bell text-primary'></i> 
                     <sup><span className='badge text-white bg-danger'>5</span></sup>
                   </Link>
                   <a className='btn'><i className="fa h4 fa-mobile-alt text-dark"></i></a>
                   <Link to="/" className="h4 btn">
                   Login
                   </Link>
                   <Button className="cart-btn">
                    <i className='fa fa-shopping-cart'></i> 3 Items

                   </Button>
                   </div>
                   </Col>
                 </Row>
                 
                  
                   {/* <Nav className="w-100"> */}
                  
                       
                         {/* </Nav> */}
                    </Container> 
             </Navbar>  
        </div>


        
        <div className={this.state.SideNavState}>
                   <MegaMenuAll />
                    </div>
                    <div onClick={this.ContentOverkayClickHandler} className={this.state.ContentOverState}>

                    </div>


      </Fragment>
    )
  }
}

export default NavMenuDesktop
