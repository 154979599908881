import React, { Component, Fragment } from 'react'
import { Navbar, Container,Row,Col,Button,Nav} from 'react-bootstrap'
import logo from '../../assets/images/easyshop.png';
import { Link } from "react-router-dom";
import MegaMenuMobile from '../home/MegaMenuMobile';


export class NavMenuMobile extends Component {
constructor(props){
  super();
  this.state={
    SideNavState:"sideNavClose",
    ContentOverState: "ContentOverlayClose"
  }
  

}

MenuBarClickHandler= () => {
  this.SideNavOpenClose();

}

ContentOverkayClickHandler=()=>{
      this.SideNavOpenClose();
}

SideNavOpenClose=()=> {
   let SideNavState=this.state.SideNavState;
   let ContentOverState=this.state.ContentOverState;
      
      if(SideNavState==="sideNavOpen"){
        this.setState({SideNavState:"sideNavClose",ContentOverState:"ContentOverlayClose"});
       }
       else{
         this.setState({SideNavState:"sideNavOpen",ContentOverState:"ContentOverlayOpen"});
       }


}
  render() {
    return (
      <Fragment>
      <div>
                <div className='TopSectionDown' >
                 <Container fluid={true}  className="fixed-top shadow-sm bg-white p-2 mb-0 ">
                 <Row className="w-100">
                   <Col lg={4} md={4}>
                   <Button className="btn" onClick={this.MenuBarClickHandler}>
                    <i className='fa fa-bars'></i>
                   </Button>

                        <Navbar.Brand href="#">
                        <img src={logo} />
                      </Navbar.Brand>  
                   
                      <Button className="cart-btn">
                    <i className='fa fa-shopping-cart'></i> 3 Items
                    </Button>

                   </Col>
                 </Row>
                    </Container> 

                    <div className={this.state.SideNavState}>
                   <MegaMenuMobile />
                    </div>
                    <div onClick={this.ContentOverkayClickHandler} className={this.state.ContentOverState}>

                    </div>
        </div>
      </div>
      
      </Fragment>
    )
  }
}

export default NavMenuMobile
