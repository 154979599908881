import React, { Component, Fragment } from 'react'
// import { motion } from "framer-motion"
import FeaturedProducts from '../components/home/FeaturedProducts'
import Catagories from '../components/home/Catagories'
import Collection from '../components/home/Collection'
import NewArrival from '../components/home/NewArrival'
import OfferProduct from '../components/home/OfferProduct'
import HomeTop from '../components/home/HomeTop'
import NavMenuDesktop from '../components/common/NavMenuDesktop'
import NavMenuMobile from '../components/common/NavMenuMobile'
import HomeTopMobile from '../components/home/HomeTopMobile'
export class HomePage extends Component {

  render() {
    return (
      <div>
          <Fragment >

            <div className="Desktop">
            <NavMenuDesktop/> 
            <HomeTop />
            </div>

            <div className="Mobile">
            <NavMenuMobile />
            <HomeTopMobile/>
           </div>
           
             <Catagories />
             <OfferProduct />
             <NewArrival />
        
             <FeaturedProducts />
            
             <Collection /> 
          </Fragment>
        
      </div>
    )
  }
}

export default HomePage
