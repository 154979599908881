import React, { Component, Fragment } from 'react'
import { Container,Row,Col,Card } from 'react-bootstrap';
export class FeaturedProducts extends Component {
  render() {
    return (
      <div>
        <Fragment>
            <Container  className="text-center">
                    <div className='section-title'>
                         <h2> FEATURED PRODUCTS</h2>
                        <p>
                            Some of Our Exclusive Collection, you may Like 
                        </p>
                    </div>
                <Row >
                    {/* Just need to add segmentation or break point  */}
                    <Col xl={3} lg={3} md={3} sm={4} xs={6}>

                    <Card  className='card image-box'>
                      
                    
                            <Card.Body>

                            <Card.Img variant="top"  className='center'
                       src="https://m.media-amazon.com/images/I/610QWfB7PcL._AC_UL320_.jpg" 
                       />
                            <Card.Title className='product-name-on-card'>
                            Wood Bed Side Table
                            </Card.Title>

                            <p className='product-price-on-card'>
                            Price Rs. 3000/-
                            </p>
                           
                            </Card.Body>
                    </Card>
                    </Col>
                    
                    <Col xl={3} lg={3} md={3} sm={4} xs={6}>

<Card  className='card image-box'>
  
   <Card.Img variant="top"  className='center'
   src="https://m.media-amazon.com/images/I/61MV0-gdZBL._AC_UL320_.jpg" 
   />
        <Card.Body>

         
        <Card.Title className='product-name-on-card'>
        Shreya Decor Sheesham  
        </Card.Title>

        <p className='product-price-on-card'>
        Price Rs. 3000/-
        </p>
       
        </Card.Body>
</Card>
</Col>
<Col xl={3} lg={3} md={3} sm={4} xs={6}>

                    <Card  className='card image-box'>
                      
                       <Card.Img   className='center'
                       src="https://m.media-amazon.com/images/I/51-X957l83L._AC_UL320_.jpg" 
                       
                       />
                            <Card.Body>

                             
                            <Card.Title className='product-name-on-card'>
                            Wood Bed Side Table
                            </Card.Title>

                            <p className='product-price-on-card'>
                            Price Rs. 3000/-
                            </p>
                           
                            </Card.Body>
                    </Card>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={4} xs={6}>

                    <Card  className='card image-box'>
                      
                       <Card.Img variant="top"  className='center'
                       src="https://m.media-amazon.com/images/I/71g2eQCKEAL._AC_UL320_.jpg" 
                       />
                            <Card.Body>

                             
                            <Card.Title className='product-name-on-card'>
                            Wood Bed Side Table
                            </Card.Title>

                            <p className='product-price-on-card'>
                            Price Rs. 3000/-
                            </p>
                           
                            </Card.Body>
                    </Card>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={4} xs={6}>

<Card  className='card image-box'>
  
   <Card.Img variant="top"  className='center'
   src="https://m.media-amazon.com/images/I/61MV0-gdZBL._AC_UL320_.jpg" 
   />
        <Card.Body>

         
        <Card.Title className='product-name-on-card'>
        Shreya Decor Sheesham  
        </Card.Title>

        <p className='product-price-on-card'>
        Price Rs. 3000/-
        </p>
       
        </Card.Body>
</Card>
</Col>
<Col xl={3} lg={3} md={3} sm={4} xs={6}>

                    <Card  className='card image-box'>
                      
                       <Card.Img   className='center'
                       src="https://m.media-amazon.com/images/I/51-X957l83L._AC_UL320_.jpg" 
                       
                       />
                            <Card.Body>

                             
                            <Card.Title className='product-name-on-card'>
                            Wood Bed Side Table
                            </Card.Title>

                            <p className='product-price-on-card'>
                            Price Rs. 3000/-
                            </p>
                           
                            </Card.Body>
                    </Card>
                    </Col>
                 
                    <Col xl={3} lg={3} md={3} sm={4} xs={6}>

                    <Card  className='card image-box'>
                      
                       <Card.Img variant="top"  className='center'
                       src="https://m.media-amazon.com/images/I/410chN2oXOL._AC_UL320_.jpg" 
                       />
                            <Card.Body>

                             
                            <Card.Title className='product-name-on-card'>
                            Wood Bed Side Table
                            </Card.Title>

                            <p className='product-price-on-card'>
                            Price Rs. 3000/-
                            </p>
                           
                            </Card.Body>
                    </Card>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={4} xs={6}>

                    <Card  className='card image-box'>
                      
                       <Card.Img variant="top"  className='center'
                       src="https://rukminim2.flixcart.com/image/416/416/kn3i1zk0/side-table/d/s/e/rosewood-sheesham-premium-sheesham-wood-bed-side-table-for-original-imagfug5qvwc8whb.jpeg" 
                       />
                            <Card.Body>

                             
                            <Card.Title className='product-name-on-card'>
                            Wood Bed Side Table
                            </Card.Title>

                            <p className='product-price-on-card'>
                            Price Rs. 3000/-
                            </p>
                           
                            </Card.Body>
                    </Card>
                    </Col>
                    
                </Row>

            </Container>
        </Fragment>
      </div>
    )
  }
}

export default FeaturedProducts
