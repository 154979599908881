import React, { Component,Fragment   } from 'react'
import { Container,Row,Col,Card  } from 'react-bootstrap';

export class Collection extends Component {
  render() {
    return (
      <div>
         <Fragment>
            <Container  className="text-center">
                    <div className='section-title'>
                         <h2> Collection</h2>
                        <p>
                            Some of Our Exclusive Collection, you may Like 
                        </p>
                    </div>


                <Row>
                <Col className='p-0' xl={3} lg={3} md={3} sm={6} xs={6}>
                    <Card  className='card image-box'>
                    
                    <Card.Img variant="top"  className='center'
                      src="https://m.media-amazon.com/images/I/718XDEWTPIL._AC_UL320_.jpg" 
                      />
                            
                           <Card.Body>
  
                           <Card.Title className='product-name-on-card'>
                           Shreya Decor Sheesham Wood Bed
                           </Card.Title>

                           <p className='product-price-on-card'>
                           Price Rs. 30000/-
                           </p>
                          
                           </Card.Body>
                   </Card>
                    </Col>

                    <Col className='p-0' xl={3} lg={3} md={3} sm={6} xs={6}>
                    <Card  className='card image-box'>
                    
                    <Card.Img variant="top"  className='center'
                      src="https://m.media-amazon.com/images/I/81BDpSIwu3L._AC_UL320_.jpg" 
                      />
                            
                           <Card.Body>
  
                           <Card.Title className='product-name-on-card'>
                           Shreya Decor Sheesham Wood Bed
                           </Card.Title>

                           <p className='product-price-on-card'>
                           Price Rs. 30000/-
                           </p>
                          
                           </Card.Body>
                   </Card>
                    </Col>

                    <Col className='p-0' xl={3} lg={3} md={3} sm={6} xs={6}>
                    <Card  className='card image-box'>
                    
                    <Card.Img variant="top"  className='center'
                      src="https://m.media-amazon.com/images/I/6145RELNfrL._AC_UL320_.jpg" 
                      />
                            
                           <Card.Body>
  
                           <Card.Title className='product-name-on-card'>
                           Shreya Decor Sheesham Wood Bed
                           </Card.Title>

                           <p className='product-price-on-card'>
                           Price Rs. 30000/-
                           </p>
                          
                           </Card.Body>
                   </Card>
                    </Col>
                    <Col className='p-0' xl={3} lg={3} md={3} sm={6} xs={6}>
                    <Card  className='card image-box w-100'>
                    
                    <Card.Img variant="top"  className='center'
                      src="https://m.media-amazon.com/images/I/71fhfSoSn9L._AC_UL320_.jpg" 
                      />
                            
                           <Card.Body>
  
                           <Card.Title className='product-name-on-card'>
                           Shreya Decor Sheesham Wood Bed 
                          
                           </Card.Title>

                           <p className='product-price-on-card'>
                           Price Rs. 30000/-
                           </p>
                          
                           </Card.Body>
                   </Card>
                    </Col>
                    <Col className='p-0' xl={3} lg={3} md={3} sm={6} xs={6}>
                    <Card  className='card image-box'>
                    
                    <Card.Img variant="top"  className='center'
                      src="https://m.media-amazon.com/images/I/61IMRs+o0iL._AC_UL320_.jpg" 
                      />
                            
                           <Card.Body>
  
                           <Card.Title className='product-name-on-card'>
                           Shreya Decor Sheesham Wood Bed
                           </Card.Title>

                           <p className='product-price-on-card'>
                           Price Rs. 30000/-
                           </p>
                          
                           </Card.Body>
                   </Card>
                    </Col>

                    <Col className='p-0' xl={3} lg={3} md={3} sm={6} xs={6}>
                    <Card  className='card image-box'>
                    
                    <Card.Img variant="top"  className='center'
                      src="https://m.media-amazon.com/images/I/61epn29QG0L._AC_UL320_.jpg" 
                      />
                            
                           <Card.Body>
  
                           <Card.Title className='product-name-on-card'>
                           Shreya Decor Sheesham Wood Bed
                           </Card.Title>

                           <p className='product-price-on-card'>
                           Price Rs. 30000/-
                           </p>
                          
                           </Card.Body>
                   </Card>
                    </Col>

                    <Col className='p-0' xl={3} lg={3} md={3} sm={6} xs={6}>
                    <Card  className='card image-box'>
                    
                    <Card.Img variant="top"  className='center'
                      src="https://m.media-amazon.com/images/I/61w9ISMyhiL._AC_UL320_.jpg" 
                      />
                            
                           <Card.Body>
  
                           <Card.Title className='product-name-on-card'>
                           Shreya Decor Sheesham Wood Bed
                           </Card.Title>

                           <p className='product-price-on-card'>
                           Price Rs. 30000/-
                           </p>
                          
                           </Card.Body>
                   </Card>
                    </Col>
                    <Col className='p-0' xl={3} lg={3} md={3} sm={6} xs={6}>
                    <Card  className='card image-box w-100'>
                    
                    <Card.Img variant="top"  className='center'
                      src="https://m.media-amazon.com/images/I/710LCvbM57L._AC_UL320_.jpg" 
                      />
                            
                           <Card.Body>
  
                           <Card.Title className='product-name-on-card'>
                           Shreya Decor Sheesham Wood Bed 
                          
                           </Card.Title>

                           <p className='product-price-on-card'>
                           Price Rs. 30000/-
                          
                           </p>
                    
                           </Card.Body>
                   </Card>
                    </Col>
                </Row>

                


                    </Container>
                    </Fragment> 
      </div>
    )
  }
}

export default Collection
