import React, { Component,Fragment } from 'react'
import { Container,Row,Card } from 'react-bootstrap';
import Slider from "react-slick";

export class NewArrival extends Component {
  constructor(props){
    super(props);
      this.next=this.next.bind(this);
      this.previous=this.previous.bind(this);
  }
  next(){
      this.slider.slickNext();
  }

  previous(){
    this.slider.slickPrev ();
  }

  render() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay:true,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
  
    return (
      <div>
        <Fragment>
            <Container  className="text-center">
                    <div className='section-title'>
                         <h2> New Arrival &nbsp;
<a href="" className='btn btn-sm ml-2 site-btn' onClick={this.previous}>
  <i className='fa fa-angle-left'> </i>
</a>
&nbsp;
<a href="" className='btn btn-sm ml-2 site-btn' onClick={this.next}>
  <i className='fa fa-angle-right'> </i>
</a>
                         </h2>
                        <p>
                            Some of Our Exclusive Collection, you may Like 
                        </p>
                    </div>


                <Row>
                        <Slider ref={c=>(this.slider=c)} {...settings}>
                        <div>
                          
                        <Card  className='card image-box'>
                    
                    <Card.Img variant="top"  className='center'
                      src="https://rukminim2.flixcart.com/image/416/416/ks7tuvk0/cases-covers/back-cover/k/a/g/b099wrzd19-foso-original-imag5u2kmqazx2gq.jpeg" 
                      />
                            
                           <Card.Body>
  
                           <Card.Title className='product-name-on-card'>
                           Shreya Decor Sheesham Wood Bed
                           </Card.Title>

                           <p className='product-price-on-card'>
                           Price Rs. 30000/-
                           </p>
                          
                           </Card.Body>
                   </Card>


                        </div>
                        <div>
                        <Card  className='card image-box'>
                    
                    <Card.Img variant="top"  className='center'
                      src="https://m.media-amazon.com/images/I/41p9Zx2nn2L.jpg" 
                      />
                            
                           <Card.Body>
  
                           <Card.Title className='product-name-on-card'>
                           Shreya Decor Sheesham Wood Bed
                           </Card.Title>

                           <p className='product-price-on-card'>
                           Price Rs. 30000/-
                           </p>
                          
                           </Card.Body>
                   </Card>
                        </div>
                        <div>
                        <Card  className='card image-box'>
                    
                    <Card.Img variant="top"  className='center'
                      src="https://m.media-amazon.com/images/I/71bPAPKfz1L._SX679_.jpg" 
                      />
                            
                           <Card.Body>
  
                           <Card.Title className='product-name-on-card'>
                           Shreya Decor Sheesham Wood Bed
                           </Card.Title>

                           <p className='product-price-on-card'>
                           Price Rs. 30000/-
                           </p>
                          
                           </Card.Body>
                   </Card>
                        </div>
                        <div>
                        <Card  className='card image-box'>
                    
                    <Card.Img variant="top"  className='center'
                      src="https://rukminim2.flixcart.com/image/416/416/ks7tuvk0/cases-covers/back-cover/k/a/g/b099wrzd19-foso-original-imag5u2kmqazx2gq.jpeg" 
                      />
                            
                           <Card.Body>
  
                           <Card.Title className='product-name-on-card'>
                           Shreya Decor Sheesham Wood Bed
                           </Card.Title>

                           <p className='product-price-on-card'>
                           Price Rs. 30000/-
                           </p>
                          
                           </Card.Body>
                   </Card>
                        </div>
                        <div>
                        <Card  className='card image-box'>
                    
                    <Card.Img variant="top"  className='center'
                      src="https://rukminim2.flixcart.com/image/416/416/ks7tuvk0/cases-covers/back-cover/k/a/g/b099wrzd19-foso-original-imag5u2kmqazx2gq.jpeg" 
                      />
                            
                           <Card.Body>
  
                           <Card.Title className='product-name-on-card'>
                           Shreya Decor Sheesham Wood Bed
                           </Card.Title>

                           <p className='product-price-on-card'>
                           Price Rs. 30000/-
                           </p>
                          
                           </Card.Body>
                   </Card>
                        </div>
                        <div>
                        <Card  className='card image-box'>
                    
                    <Card.Img variant="top"  className='center'
                      src="https://rukminim2.flixcart.com/image/416/416/ks7tuvk0/cases-covers/back-cover/k/a/g/b099wrzd19-foso-original-imag5u2kmqazx2gq.jpeg" 
                      />
                            
                           <Card.Body>
  
                           <Card.Title className='product-name-on-card'>
                           Shreya Decor Sheesham Wood Bed
                           </Card.Title>

                           <p className='product-price-on-card'>
                           Price Rs. 30000/-
                           </p>
                          
                           </Card.Body>
                   </Card>
                        </div>
                        <div>
                        <Card  className='card image-box'>
                    
                    <Card.Img variant="top"  className='center'
                      src="https://rukminim2.flixcart.com/image/416/416/ks7tuvk0/cases-covers/back-cover/k/a/g/b099wrzd19-foso-original-imag5u2kmqazx2gq.jpeg" 
                      />
                            
                           <Card.Body>
  
                           <Card.Title className='product-name-on-card'>
                           Shreya Decor Sheesham Wood Bed
                           </Card.Title>

                           <p className='product-price-on-card'>
                           Price Rs. 30000/-
                           </p>
                          
                           </Card.Body>
                   </Card>
                        </div>
                        <div>
                        <Card  className='card image-box'>
                    
                    <Card.Img variant="top"  className='center'
                      src="https://rukminim2.flixcart.com/image/416/416/ks7tuvk0/cases-covers/back-cover/k/a/g/b099wrzd19-foso-original-imag5u2kmqazx2gq.jpeg" 
                      />
                            
                           <Card.Body>
  
                           <Card.Title className='product-name-on-card'>
                           Shreya Decor Sheesham Wood Bed
                           </Card.Title>

                           <p className='product-price-on-card'>
                           Price Rs. 30000/-
                           </p>
                          
                           </Card.Body>
                   </Card>
                        </div>
                        </Slider>
                </Row>
                </Container>
                </Fragment>
      </div>
    )
  }
}

export default NewArrival
