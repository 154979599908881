import React, { Component, Fragment } from 'react'
import {
    Routes,
    Route,
  } from "react-router-dom";
import HomePage from '../pages/HomePage';

export class AppRoute extends Component {
  render() {
    return (
      <div>
          <Fragment>
             <Routes>
                <Route path="/" element={<HomePage />} />
             </Routes>
          </Fragment>
        
      </div>
    )
  }
}

export default AppRoute
