import React, { Component,Fragment } from 'react'
import { motion } from 'framer-motion'
import { Card, Container,Row,Col } from 'react-bootstrap';
export class OfferProduct extends Component {
  render() {
    return (
          <Fragment>
      <Container  className="text-center">
              <div className='section-title'>
                   <h2> Special Offers</h2>
                  <p>
                      Some of Our Exclusive Collection, you may Like 
                  </p>
              </div>
            <Row>
            <Col lg={3} md={3} sm={4} xs={6}>
                    <Card>
                        <Card.Body>
                            <motion.img className="w-100"
                            initial={{
                                x:-100,
                                opacity:.1
                            }}
                            animate={{
                                x:0,
                                opacity:1,

                            }}
                            transition={{
                                type:"spring",
                                stiffness:75,
                                damping:4
                            }}
                            whileHover={{
                                scale:1.1
                            }}
                            whileTap={{
                                scale:0.9
                            }}
                            dragConstraints={{
                                right:20,
                                left:-20,
                                // top:5,
                                // bottom:5
                            }}
                            src="https://m.media-amazon.com/images/I/31UPctqOu5L.jpg" 
                            />
                            <p className='mt-3'>
                            Mayflower Engineered Wood King Box Bed 
                            </p> &nbsp;
                            <span className="text-danger">
                            ₹ 4585 
                            </span> &nbsp;
                            <span className='pricenotoffer'>
                            ₹30000/-
                           </span> 15% off
                            
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3} md={3} sm={4} xs={6}>
                    <Card>
                        <Card.Body>
                            <motion.img className="w-100"
                            initial={{
                                x:-100,
                                opacity:.1
                            }}
                            animate={{
                                x:0,
                                opacity:1,

                            }}
                            transition={{
                                type:"spring",
                                stiffness:75,
                                damping:4
                            }}
                            whileHover={{
                                scale:1.1
                            }}
                            whileTap={{
                                scale:0.9
                            }}
                            dragConstraints={{
                                right:20,
                                left:-20,
                                // top:5,
                                // bottom:5
                            }}
                            src="https://m.media-amazon.com/images/I/41BnHjRP0ZS.jpg" 
                            />
                            <p className='mt-3'>
                            Mayflower Engineered Wood King Box Bed 
                            </p>
                            &nbsp;
                            <span className="text-danger">
                            ₹ 4585 
                            </span> &nbsp;
                            <span className='pricenotoffer'>
                            ₹30000/-
                           </span> 15% off
                            
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3} md={3} sm={4} xs={6}>
                    <Card>
                        <Card.Body>
                            <motion.img className="w-100"
                            initial={{
                                x:-100,
                                opacity:.1
                            }}
                            animate={{
                                x:0,
                                opacity:1,

                            }}
                            transition={{
                                type:"spring",
                                stiffness:75,
                                damping:4
                            }}
                            whileHover={{
                                scale:1.1
                            }}
                            whileTap={{
                                scale:0.9
                            }}
                            dragConstraints={{
                                right:20,
                                left:-20,
                                // top:5,
                                // bottom:5
                            }}
                            src="https://m.media-amazon.com/images/I/71iPH+HSz-L.jpg" 
                            />
                            <p className='mt-3'>
                            Mayflower Engineered Wood King Box Bed 
                            </p>
                            &nbsp;
                            <span className="text-danger">
                            ₹ 4585 
                            </span> &nbsp;
                            <span className='pricenotoffer'>
                            ₹30000/-
                           </span> 15% off
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3} md={3} sm={4} xs={6}>
                    <Card>
                        <Card.Body>
                            <motion.img className="w-100"
                            initial={{
                                x:-100,
                                opacity:.1
                            }}
                            animate={{
                                x:0,
                                opacity:1,

                            }}
                            transition={{
                                type:"spring",
                                stiffness:75,
                                damping:4
                            }}
                            whileHover={{
                                scale:1.02
                            }}
                            whileTap={{
                                scale:0.9
                            }}
                            dragConstraints={{
                                right:20,
                                left:-20,
                                // top:5,
                                // bottom:5
                            }}
                            src="https://m.media-amazon.com/images/I/41ald8X03hL.jpg" 
                            />
                            <p className='mt-3'>
                            Mayflower Engineered Wood King Box Bed 
                            </p>
                            &nbsp;
                            <span className="text-danger">
                            ₹ 4585 
                            </span> &nbsp;
                            <span className='pricenotoffer'>
                            ₹30000/-
                           </span> 15% off
                        </Card.Body>
                    </Card>
                </Col>
        

            </Row>

            </Container>
        </Fragment>

    )
  }
}

export default OfferProduct