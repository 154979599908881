import React, { Component, Fragment } from 'react'
import { Container,Row,Col,Card  } from 'react-bootstrap';

export class Catagories extends Component {
  render() {
    return (
      <div>
        <Fragment>
            <Container   className="text-center">
                    {/* <div className='section-title'>
                         <h2> CATEGORIES</h2>
                        <p>
                            Some of Our Exclusive Collection, you may Like 
                        </p>
                    </div> */}
                
                
                <Row >
                    <Col key={1} xl={6} lg={6} md={2} sm={12} xs={12}>
                            <Row >
                                <Col className="p-1 "   xl={3} lg={3} md={3} sm={6} xs={6}>
                                 <Card  className='h-100 w-100 text-center '>
                                <Card.Body>
                                <Card.Img variant="top"  className='center rounded-circle border border-primary'
                                    src="https://rukminim2.flixcart.com/flap/128/128/image/71050627a56b4693.png?q=100" 
                                    />
                                    <h5 className='category-name'> TRAVELS</h5>
                                </Card.Body>
                                </Card>
                                </Col>
                                <Col className="p-1"   xl={3} lg={3} md={3} sm={6} xs={6}>
                                 <Card  className='h-100 w-100 text-center'>
                                <Card.Body>
                                <Card.Img variant="top"  className='center rounded-circle border border-primary'
                                    src="https://rukminim2.flixcart.com/flap/128/128/image/0ff199d1bd27eb98.png?q=100" 
                                    />
                                    <h5 className='category-name'> HOME</h5>
                                </Card.Body>
                                </Card>
                                </Col>
                                <Col className="p-1"   xl={3} lg={3} md={3} sm={6} xs={6}>
                                 <Card  className='h-100 w-100 text-center'>
                                <Card.Body>
                                <Card.Img variant="top"  className='center rounded-circle border border-primary'
                                    src="https://rukminim2.flixcart.com/flap/128/128/image/f15c02bfeb02d15d.png?q=100" 
                                    />
                                    <h5 className='category-name'> GIFTS</h5>
                                </Card.Body>
                                </Card>
                                </Col>
                                <Col className="p-1"  xl={3} lg={3} md={3} sm={6} xs={6}>
                                 <Card  className='h-100 w-100 text-center'>
                                <Card.Body>
                                <Card.Img variant="top"  className='center rounded-circle border border-primary'
                                    src="https://rukminim2.flixcart.com/flap/128/128/image/ab7e2b022a4587dd.jpg?q=100" 
                                    />
                                    <h5 className='category-name'> MOBILE</h5>
                                </Card.Body>
                                </Card>
                                </Col>
                      

                                
                            </Row>

                    </Col>


                    <Col key={2} xl={6} lg={6} md={2} sm={12} xs={12}>
                            <Row >
                                <Col className="p-1"   xl={3} lg={3} md={3} sm={6} xs={6}>
                                 <Card  className='h-100 w-100 text-center'>
                                <Card.Body>
                                <Card.Img variant="top"  className='center rounded-circle border border-primary'
                                    src="https://rukminim2.flixcart.com/flap/128/128/image/69c6589653afdb9a.png?q=100" 
                                    />
                                    <h5 className='category-name'> MOBILE</h5>
                                </Card.Body>
                                </Card>
                                </Col>
                                <Col className="p-1" xl={3} lg={3} md={3} sm={6} xs={6}>
                                 <Card  className='h-100 w-100 text-center'>
                                <Card.Body>
                                <Card.Img variant="top"  className='center rounded-circle border border-primary'
                                    src="https://rukminim2.flixcart.com/flap/128/128/image/c12afc017e6f24cb.png?q=100" 
                                    />
                                    <h5 className='category-name'> MOBILE</h5>
                                </Card.Body>
                                </Card>
                                </Col>
                                <Col className="p-1"   xl={3} lg={3} md={3} sm={6} xs={6}>
                                 <Card  className='h-100 w-100 text-center'>
                                <Card.Body>
                                <Card.Img variant="top"  className='center rounded-circle border border-primary'
                                    src="https://rukminim2.flixcart.com/flap/128/128/image/22fddf3c7da4c4f4.png?q=100" 
                                    />
                                    <h5 className='category-name'> MOBILE</h5>
                                </Card.Body>
                                </Card>
                                </Col>
                                <Col className="p-1"  xl={3} lg={3} md={3} sm={6} xs={6}>
                                 <Card  className='h-100 w-100 text-center'>
                                <Card.Body>
                                <Card.Img variant="top"  className='center rounded-circle border border-primary'
                                    src="https://rukminim2.flixcart.com/flap/128/128/image/29327f40e9c4d26b.png?q=100" 
                                    />
                                    <h5 className='category-name'> MOBILE</h5>
                                </Card.Body>
                                </Card>
                                </Col>
                      

                                
                            </Row>

                    </Col>

                </Row>



            </Container>
        </Fragment>
      </div>
    )
  }
}

export default Catagories
