import React, { Component,Fragment } from 'react'
import { Row,Container,Col } from 'react-bootstrap'
import HomeSlider from './HomeSlider'

export class HomeTopMobile extends Component {
  render() {
    return (
        <Fragment  >
          <Container fluid={true}  style={{overflowX: 'hidden',overflowY: 'hidden'}}>
              <Row className='p-0 m-0 overflow-hidden'>
                  <Col lg={12} md={12} sm={12}  >
                  <HomeSlider />
                  </Col>
              </Row>
          </Container>
        
      </Fragment>
    )
  }
}

export default HomeTopMobile
