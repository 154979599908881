import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

//Slick
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

//Custom Css
import '../src/assets/css/custom.css';
import '../src/assets/css/fontawesome.css';
import '../src/assets/css/animate.min.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //  <React.StrictMode>
 
    <App />
  // </React.StrictMode>
);

 
reportWebVitals();
